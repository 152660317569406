import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FLAGS } from './constants/flags';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import ucFirst from './generals/ucFirst';
import useTranslationState from './generals/useTranslationState';

interface IntroProps {
    noMenu?: boolean;
}

function Intro(props: IntroProps) {
    const { baseLanguage, targetLanguage } = useParams();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const translations = useTranslationState({
        iAlreadyKnowSomeX: '',
        iDontKnowAnyX: '',
        letsPutYouAtAProperLevel: '',
        startLearning: '',
        weWillTestYourLevel: '',
        youWillStartWithTheBasics: '',
    });

    const flagExists = (flag: string) => FLAGS.includes(flag);

    interface ActionBoxProps {
        label: string;
        subLabel?: string;
        link: string;
    }

    function ActionBox(props: ActionBoxProps) {
        return (
            <Box
                sx={{
                    width: 200,
                    height: 130,
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    gap: 2,
                    cursor: 'pointer',
                    flexDirection: 'column',
                    position: 'relative',
                }}
                onClick={() => {
                    navigate(props.link);
                }}
            >
                <Typography
                    variant='subtitle1'
                    component='p'
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {props.label}
                </Typography>
                {props.subLabel &&
                    <Typography
                        variant='caption'
                        component='p'
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        {props.subLabel}
                    </Typography>
                }
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                gap: 2,
            }}
        >
            <Typography
                variant='h3'
                component='h1'
                sx={{
                    textAlign: 'center',
                }}
            >
                {translations.startLearning}
            </Typography>
            <Typography
                variant='body1'
                component='p'
                sx={{
                    textAlign: 'center',
                }}
            >
                {translations.letsPutYouAtAProperLevel}
            </Typography>
            {flagExists(targetLanguage!) &&
                <img
                    src={'/' + targetLanguage + '.png'}
                    style={{
                        height: 40,
                        borderRadius: 5,
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                />
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 2,
                    marginTop: 4,
                }}
            >
                <ActionBox
                    label={translations.iAlreadyKnowSomeX.replace('X', ucFirst(targetLanguage!))}
                    subLabel={translations.weWillTestYourLevel}
                    link={'/courses/' + baseLanguage + '/' + targetLanguage + '/intro/lesson' + (props.noMenu ? '/nomenu' : '')}
                />
                <ActionBox
                    label={translations.iDontKnowAnyX.replace('X', ucFirst(targetLanguage!))}
                    subLabel={translations.youWillStartWithTheBasics}
                    link={'/home'}
                />
            </Box>
        </Box>
    );
}

export default Intro;
