import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseButton from '../../generals/closebutton';
import { theme } from '../../generals/theme';
import CommentsResponse from '../../responses/Comments';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import { Comment2EndpointPostPromise } from '../../routes/Comment';
import { Comments2EndpointGetHook, Comments2EndpointGetPromise } from '../../routes/Comments';

interface Props {
    questionId: string;
    show: boolean;
    close: () => void;
}

function CommentView(props: Props) {
    const [commentsState, setCommentsState] = useState<CommentsResponse | EmptyResponseType>(EmptyResponse);

    const commentsResponse = Comments2EndpointGetHook(props.questionId);

    useEffect(() => {
        if (commentsResponse.data) {
            setCommentsState(commentsResponse);
        }
    }, [commentsResponse]);

    const [comment, setComment] = useState('');

    const handleComment = () => {
        const promise = Comment2EndpointPostPromise(props.questionId, comment, '');
        promise.then((response) => {
            if (response.status === 200) {
                const newComments = Comments2EndpointGetPromise(props.questionId);

                newComments.then((response) => {
                    if (response.status === 200) {
                        setCommentsState(response);
                        setComment('');
                    }
                });
            }
        });
    };

    return (
        <>
            {commentsState.status === 200 &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9999,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <CloseButton
                        close={props.close}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            paddingTop: 4,
                            boxSizing: 'border-box',
                            height: '80vh',
                            width: '100vw',
                        }}
                    >
                        {commentsState.data.comments.map((comment: any) => (
                            <>
                                {comment.parent === '' &&
                                        <Box
                                            key={comment.id}
                                            sx={{
                                                padding: 2,
                                                margin: 2,
                                                border: '1px solid black',
                                                borderRadius: 5,
                                            }}
                                        >
                                            <Typography
                                                variant='body1'
                                                component='div'
                                            >
                                                {comment.comment}
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                component='div'
                                            >
                                                {comment.userData.name}
                                            </Typography>
                                        </Box>
                                }
                            </>
                        )
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            height: '20vh',
                            width: '100vw',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            sx={{
                                width: '80%',
                            }}
                            label='Kommentar'
                            variant='outlined'
                            multiline
                            rows={3}
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleComment();
                                }
                            }}
                        />
                    </Box>
                </Box>
            }
        </>
    );
}

export default CommentView;
