import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import Sidenav from './profile/Sidenav';
import { Language2EndpointGetHook } from './routes/Language';

function Profile() {
    const [translations, setTranslations] = useState({
        logout: '',
        statistics: '',
        profile: '',
        options: '',
        shop: '',
        payment: '',
        support: '',
        faq: '',
    });

    const isMobile = useIsMobile();

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userState, setUserState] = useState<any>({});

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
            setIsLoggedIn(translationsResponse.userState.isLogin);
            setUserState(translationsResponse.userState.userModel);
            setIsLoaded(true);
        }
    }, [translationsResponse.data]);

    const navigate = useNavigate();

    if (!isLoggedIn && isLoaded) {
        navigate('/');
    }

    return (
        <Box>
            <Grid container spacing={2}>
                {!isMobile &&
                    <Grid item xs={12} md={2}>
                        <Sidenav
                            translations={translations}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={10} >
                    <Container maxWidth='xl'>
                        <Grid container spacing={2}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={5} xs={12}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                        height: 'calc(100vh - 68px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            border: '1px solid ' + theme.palette.grey[300],
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: '50%',
                                                width: '100%',
                                                borderBottom: 1,
                                            }}
                                        >
                                            <Typography
                                                variant='body1'
                                                component='h4'
                                            >
                                                {userState.email}
                                            </Typography>
                                            <Typography
                                                variant='body1'
                                                component='h4'
                                            >
                                                {userState.name}
                                            </Typography>
                                            <Typography
                                                variant='body1'
                                                component='h4'
                                            >
                                                {userState.language}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                height: '50%',
                                                width: '100%',
                                            }}
                                        >
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={1} xs={12}>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 'calc(100vh - 68px)',
                                        textAlign: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            border: '1px solid ' + theme.palette.grey[300],
                                            display: 'flex',
                                            flexDirection: 'column',
                                            p: 2,
                                            gap: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: 60,
                                                width: '100%',
                                                backgroundColor: 'gray',
                                                borderRadius: 4,
                                            }}
                                        >
                                        </Box>
                                        <Box
                                            sx={{
                                                height: 60,
                                                width: '100%',
                                                backgroundColor: 'gray',
                                                borderRadius: 4,
                                            }}
                                        >
                                        </Box>
                                        <Box
                                            sx={{
                                                height: 60,
                                                width: '100%',
                                                backgroundColor: 'gray',
                                                borderRadius: 4,
                                            }}
                                        >
                                        </Box>
                                        <Box
                                            sx={{
                                                height: 60,
                                                width: '100%',
                                                backgroundColor: 'gray',
                                                borderRadius: 4,
                                            }}
                                        >
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>

            </Grid >

        </Box >
    );
}

export default Profile;
