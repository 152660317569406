import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TTS } from '../../generals/speak';
import { GetVocabTranslation2EndpointGetPromise } from '../../routes/GetVocabTranslation';
import styles from './QuestionText.module.css';

interface Props {
    question: string;
    questionId: string;
    lection: string;
    direction: 'both' | 'base' | 'target';
    entireTranslation: string;
    language: string;
    showTranslation?: boolean;
}

interface WordWithTranslation {
    word: string;
    translation?: string;
}

const QuestionText: React.FC<Props> = (props: Props) => {
    const [translatedWords, setTranslatedWords] = useState<WordWithTranslation[]>([]);

    useEffect(() => {
        const fetchTranslations = async () => {
            const words = props.question.match(/([\p{L}]+|\s+)/gu);
            if (!words) {
                return;
            }

            const translationPromises = words.map(async (word) => {
                if (word.trim().length === 0) {
                    return { word };
                }

                try {
                    const response = await GetVocabTranslation2EndpointGetPromise(word, props.lection, props.direction);
                    return { word, translation: response.data.translation };
                } catch {
                    return { word };
                }
            });

            const translations = await Promise.all(translationPromises);
            setTranslatedWords(translations);
        };

        fetchTranslations();
    }, [props.question, props.lection, props.direction]);

    const hasAnyTranslations = translatedWords.some((wordData) => wordData.translation !== undefined);

    const renderTextWithTooltips = () => {
        const textElements = props.question.split(/([\p{L}]+|\s+)/gu);

        if (!hasAnyTranslations) {
            console.log('No translations found');

            return (
                <Tooltip
                    title={props.entireTranslation}
                    arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                >
                    <span
                        className={styles.underlineSpan}
                        onMouseOver={() => {
                            if (props.direction === 'both' || props.direction === 'base') {
                                TTS(props.question, props.language);
                            }
                        }}
                    >
                        {props.question}
                    </span>
                </Tooltip>
            );
        }

        return textElements.map((text, index) => {
            const wordData = translatedWords.find((entry) => entry.word === text);
            if (wordData && wordData.translation) {
                return (
                    <Tooltip
                        key={index}
                        title={wordData.translation}
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                    >
                        <span
                            className={styles.underlineSpan}
                            onMouseOver={() => {
                                if (props.direction === 'both' || props.direction === 'base') {
                                    TTS(text, props.language);
                                }
                            }}
                        >
                            {text}
                        </span>
                    </Tooltip>
                );
            }
            return <span key={index}>{text}</span>; // Return non-translatable text as is
        });
    };

    return (
        <Box>
            <Typography variant="h5">
                {renderTextWithTooltips()}
            </Typography>
            {props.showTranslation &&
                <Typography variant="body1">
                    {props.entireTranslation}
                </Typography>
            }
        </Box>
    );
};

export default QuestionText;
