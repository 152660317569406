import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function ListTables2EndpointGetHook(
) {
    return GetWithHook(routes.ListTables, {
    });
}

export function ListTables2EndpointGetPromise(
) {
    return GetWithPromise(routes.ListTables, {
    });
}

