import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { MatchingWordModel } from '../models/MatchingWordModel';
import { QuestionModel } from '../models/QuestionModel';
import { getHost } from '../modules/api/client';
import { getImage } from './getImage';

interface Props {
    question?: QuestionModel;
    vocab?: MatchingWordModel;
    height?: string;
    width?: string;
    language: string;
}

function Char(props: Props) {
    const [imageState, setImageState] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        if (props.question !== undefined && props.question.image !== '') {
            setImageState(getHost() + '/image.php?file=' + props.question.image);
        } else if (props.vocab !== undefined && props.vocab.image !== '') {
            setImageState(getHost() + '/image.php?file=' + props.vocab.image);
        } else {
            setImageState(getImage(props.language));
        }
    }, [props.question, props.vocab, props.language]);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleImageError = () => {
        setImageState(getImage(props.language));
    };

    return (
        <Box>
            {imageState !== '' &&
                <img
                    src={imageState}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    alt="question"
                    style={{
                        borderRadius: '10px',
                        height: props.height ?? '100px',
                        width: props.width ?? '100px',
                    }}
                />
            }
        </Box>
    );
}

export default Char;
