import { theme } from './theme';
import { AppBar, Badge, BottomNavigation, BottomNavigationAction, Box, Button, FormControl, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Language2EndpointGetHook } from '../routes/Language';
import EmptyResponseType from '../responses/EmptyResponseType';
import EmptyResponse from '../responses/EmptyResponse';
import { Abc, AutoGraph, Chat, FitnessCenter, Home, Person, PersonOutline, Settings, Whatshot } from '@mui/icons-material';
import { getCurrentPage } from '../modules/api/client';
import ExperienceResponse from '../responses/Experience';
import { Experience2EndpointGetHook, Experience2EndpointGetPromise } from '../routes/Experience';
import StreakResponse from '../responses/Streak';
import { Streak2EndpointGetHook, Streak2EndpointGetPromise } from '../routes/Streak';
import PhasesCountResponse from '../responses/PhasesCount';
import { PhasesCount2EndpointGetHook, PhasesCount2EndpointGetPromise } from '../routes/PhasesCount';
import CoursesResponse from '../responses/Courses';
import { Courses2EndpointGetHook } from '../routes/Courses';
import BetterCourseSwitcher from '../courses/BetterCourseSwitcher';
import useEndpoint from './useEndpoint';
import LectionsResponse from '../responses/Lections';
import { Lections2EndpointGetHook } from '../routes/Lections';
import CreateCourse from './CreateCourse';
import NewLoginButton from './NewLoginButton';

interface Props {
    children: React.ReactNode;
    noMenu?: boolean;
}

export default function Website(props: Props) {
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        lections: '',
        vocab: '',
        grammar: '',
        shop: '',
        alphabet: '',
        letters: '',
        admin: '',
        chatbots: '',
        logout: '',
        profile: '',
        login: '',
        email: '',
        resendToken: '',
        onetimepin: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    const [isLogin, setIsLogin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
            setIsLogin(translationsResponse.userState.isLogin);
            setIsAdmin(translationsResponse.userState.userModel.role === 'admin');
        }
    }, [translationsResponse.data]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const course = localStorage.getItem('course') || '';

    const setCourse = (course: string) => {
        localStorage.setItem('course', course);
        window.location.reload();
    };

    const [lectionsState, setLectionsState] = useEndpoint<LectionsResponse>(Lections2EndpointGetHook(course));

    const [coursesState, setCoursesState] = useState<CoursesResponse | EmptyResponseType>(EmptyResponse);

    const coursesResponse = Courses2EndpointGetHook('');

    useEffect(() => {
        if (coursesResponse.data) {
            setCoursesState(coursesResponse);
        }
    }, [coursesResponse.data]);

    const translateCourseIntoBaseLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[0];
    };

    const translateCourseIntoTargetLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[1];
    };

    const [phasesCountState, setPhasesCountState] = useState<PhasesCountResponse | EmptyResponseType>(EmptyResponse);

    const phasesCountResponse = PhasesCount2EndpointGetHook(translateCourseIntoBaseLanguage(course), translateCourseIntoTargetLanguage(course));

    useEffect(() => {
        if (phasesCountResponse.data) {
            setPhasesCountState(phasesCountResponse);
        }
    }, [phasesCountResponse.data]);

    const [initialExperience, setInitialExperience] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PhasesCount2EndpointGetPromise(translateCourseIntoBaseLanguage(course), translateCourseIntoTargetLanguage(course));
                setPhasesCountState(response);
            } catch (error) {
                console.error(error);
            }
        };

        const intervalId = setInterval(fetchData, 5000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, [course]);

    const [streakState, setStreakState] = useState<StreakResponse | EmptyResponseType>(EmptyResponse);

    const streakResponse = Streak2EndpointGetHook();

    useEffect(() => {
        if (streakResponse.data) {
            setStreakState(streakResponse);
        }
    }, [streakResponse.data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Streak2EndpointGetPromise();
                setStreakState(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const intervalId = setInterval(fetchData, 50000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const [experienceState, setExperienceState] = useState<ExperienceResponse | EmptyResponseType>(EmptyResponse);

    const experienceResponse = Experience2EndpointGetHook();

    useEffect(() => {
        if (experienceResponse.data) {
            setExperienceState(experienceResponse);
            setInitialExperience(experienceResponse.data.experience);
        }
    }, [experienceResponse.data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Experience2EndpointGetPromise();
                setExperienceState(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const intervalId = setInterval(fetchData, 5000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div
            style={{
                minHeight: '100vh',
                maxWidth: '100vw',
                width: '100%',
            }}
        >
            {!isMobile && !props.noMenu &&
                <AppBar
                    position="sticky"
                    elevation={0}
                >
                    <Toolbar
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: theme.palette.grey[100],
                            borderBottom: '0.1px solid #c0c0c0',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => { navigate('/home'); }}
                        >
                            <img
                                src="/logo.jpeg"
                                alt="logo"
                                style={{
                                    width: 30,
                                    height: 30,
                                    marginRight: 10,
                                    borderRadius: 50,
                                }}
                            />
                            lairner.app
                        </Box>
                        <Box
                            sx={{
                                marginLeft: 'auto',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            {isLogin && coursesState.status === 200 && coursesState.data.courses.length > 0 &&
                                <>
                                    {isAdmin &&
                                        <FormControl>
                                            <Button
                                                variant="text"
                                                onClick={() => {
                                                    navigate('/admin/overview');
                                                }}
                                                sx={{
                                                    marginRight: 5,
                                                }}
                                            >
                                                <Settings
                                                />
                                            </Button>
                                        </FormControl>
                                    }
                                    <Button
                                        color="inherit"
                                        onClick={() => {
                                            navigate('/home');
                                        }}
                                        sx={{
                                            color: getCurrentPage() === 'lections' ? 'primary.dark' : 'inherit',
                                            mr: 1,
                                        }}
                                    >
                                        <Home
                                            sx={{ marginRight: 1 }}
                                        />
                                        {translations.lections}
                                    </Button>
                                    <Button
                                        color="inherit"
                                        onClick={() => {
                                            navigate('/vocab/' + translateCourseIntoBaseLanguage(course) + '/' + translateCourseIntoTargetLanguage(course));
                                        }}
                                        sx={{
                                            color: getCurrentPage() === 'vocab' ? 'primary.dark' : 'inherit',
                                            mr: 1,
                                        }}
                                    >
                                        <FitnessCenter
                                            sx={{ marginRight: 1 }}
                                        />
                                        {translations.vocab}
                                        {phasesCountState.data.dueCount > 0 &&
                                            <Badge
                                                badgeContent={phasesCountState.data.dueCount}
                                                color="error"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 5,
                                                    right: 5,
                                                }}
                                            />
                                        }
                                    </Button>
                                    {lectionsState.status === 200 && lectionsState.data.letters.length > 0 &&
                                        <Button
                                            onClick={() => {
                                                navigate('/alphabet');
                                            }}
                                            sx={{
                                                color: getCurrentPage() === 'alphabet' ? 'primary.dark' : 'inherit',
                                                mr: 1,
                                            }}
                                        >
                                            <Abc
                                                sx={{ marginRight: 1 }}
                                            />
                                            {translations.alphabet}
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => {
                                            navigate('/chatbots');
                                        }}
                                        sx={{
                                            color: getCurrentPage() === 'chatbots' ? 'primary.dark' : 'inherit',
                                        }}
                                    >
                                        <Chat
                                            sx={{ marginRight: 1 }}
                                        />
                                        {translations.chatbots}
                                    </Button>
                                    {false &&
                                        <>
                                            <Button
                                                onClick={() => {
                                                    navigate('/grammar');
                                                }}
                                                sx={{
                                                    color: getCurrentPage() === 'grammar' ? 'primary.dark' : 'inherit',
                                                }}
                                                disabled
                                            >
                                                {translations.grammar}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    navigate('/letters');
                                                }}
                                                sx={{
                                                    color: getCurrentPage() === 'vocab' ? 'primary.dark' : 'inherit',
                                                }}
                                                disabled
                                            >
                                                {translations.letters}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    navigate('/shop');
                                                }}
                                                sx={{
                                                    color: getCurrentPage() === 'shop' ? 'primary.dark' : 'inherit',
                                                }}
                                                disabled
                                            >
                                                {translations.shop}
                                            </Button>
                                        </>
                                    }
                                    {streakState.status === 200 &&
                                        <Button
                                            color="inherit"
                                            variant="text"
                                            sx={{
                                                marginRight: 1,
                                                marginLeft: 4,
                                                color: streakState.data.streak > 0 ? 'primary.dark' : 'inherit',
                                            }}
                                        >
                                            <Whatshot
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            />
                                            {streakState.data.streak}
                                        </Button>
                                    }
                                    {experienceState.status === 200 && experienceState.data.experience > 0 &&
                                        <Button
                                            color="inherit"
                                            sx={{
                                                color: experienceState.data.experience > initialExperience ? 'primary.dark' : 'inherit',
                                            }}
                                        >
                                            <AutoGraph
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            />
                                            {experienceState.data.experience}
                                        </Button>
                                    }
                                    {isLogin &&
                                        <IconButton
                                            onClick={() => {
                                                navigate('/profile');
                                            }}
                                            sx={{
                                                color: getCurrentPage() === 'profile' ? 'primary.dark' : 'inherit',
                                                border: '1px solid #c0c0c0',
                                                mx: 2,
                                            }}
                                        >
                                            <PersonOutline
                                            />
                                        </IconButton>
                                    }
                                    {isLogin && isAdmin &&
                                        <CreateCourse
                                        />
                                    }
                                    <BetterCourseSwitcher
                                        course={course}
                                        setCourse={setCourse}
                                    />
                                </>
                            }
                            {experienceState.status !== 102 && experienceState.userState.isLogin === false &&
                                <NewLoginButton
                                    translations={translations}
                                />
                            }
                        </Box>
                    </Toolbar>
                </AppBar>
            }
            {isMobile && !props.noMenu &&
                <AppBar
                    position="sticky"
                    elevation={0}
                >
                    <Toolbar
                        sx={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme.palette.grey[50],
                            borderBottom: '0.1px solid #c0c0c0',
                        }}
                    >
                        <img
                            onClick={() => { navigate('/home'); }}
                            src="/logo.jpeg"
                            alt="logo"
                            style={{
                                width: 30,
                                height: 30,
                                marginRight: 10,
                                borderRadius: 50,
                                cursor: 'pointer',
                            }}
                        />
                        {isLogin && streakState.status === 200 && experienceState.status === 200 && coursesState.status === 200 && coursesState.data.courses.length > 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Button
                                    color="inherit"
                                    variant="text"
                                    sx={{
                                        color: streakState.data.streak > 0 ? 'primary.dark' : 'inherit',
                                    }}
                                >
                                    <Whatshot
                                        sx={{
                                            marginRight: 1,
                                        }}
                                    />
                                    {streakState.data.streak}
                                </Button>
                                <Button
                                    color="inherit"
                                    variant="text"
                                    sx={{
                                        color: experienceState.data.experience > initialExperience ? 'primary.dark' : 'inherit',
                                    }}
                                >
                                    <AutoGraph
                                        sx={{
                                            marginRight: 1,
                                        }}
                                    />
                                    {experienceState.data.experience}
                                </Button>
                                {isLogin && isAdmin &&
                                    <CreateCourse
                                    />
                                }
                                <BetterCourseSwitcher
                                    course={course}
                                    setCourse={setCourse}
                                />
                            </Box>
                        }
                        {experienceState.status !== 102 && experienceState.userState.isLogin === false &&
                            <NewLoginButton
                                translations={translations}
                            />
                        }
                    </Toolbar>
                </AppBar>
            }
            {props.children}
            {isMobile && !props.noMenu && isLogin &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: 1,
                        height: 'auto',
                        minHeight: 75,
                        display: { xs: 'block', md: 'none' },
                        zIndex: 1003,
                        borderTop: '1px solid #e0e0e0',
                        color: 'text.secondary',
                        backgroundColor: 'background.paper',
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label={translations.lections}
                            icon={<HomeIcon />}
                            onClick={() => {
                                navigate('/home');
                            }}
                            sx={{
                                color: getCurrentPage() === 'lections' ? 'primary.dark' : 'inherit',
                            }}
                        />
                        <BottomNavigationAction
                            label={translations.vocab}
                            icon={
                                <>
                                    <FitnessCenter />
                                    <Badge
                                        badgeContent={phasesCountState.data.dueCount}
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 11,
                                            left: '60%',
                                        }}
                                    >
                                    </Badge>
                                </>
                            }
                            onClick={() => {
                                navigate('/vocab/' + translateCourseIntoBaseLanguage(course) + '/' + translateCourseIntoTargetLanguage(course));
                            }}
                            sx={{
                                color: getCurrentPage() === 'vocab' ? 'primary.dark' : 'inherit',
                            }}
                        />
                        {lectionsState.status === 200 && lectionsState.data.letters.length > 0 &&
                            <BottomNavigationAction
                                label={translations.alphabet}
                                icon={
                                    <Abc />
                                }
                                onClick={() => {
                                    navigate('/alphabet');
                                }}
                                sx={{
                                    color: getCurrentPage() === 'alphabet' ? 'primary.dark' : 'inherit',
                                }}
                            />
                        }
                        <BottomNavigationAction
                            label={translations.profile}
                            icon={<Person />}
                            onClick={() => {
                                navigate('/profile');
                            }}
                            sx={{
                                color: getCurrentPage() === 'profile' ? 'primary.dark' : 'inherit',
                            }}
                        />
                    </BottomNavigation>
                </Box>
            }
        </div>
    );
}

