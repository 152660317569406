import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Phases2EndpointGetHook(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
) {
    return GetWithHook(routes.Phases, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
    });
}

export function Phases2EndpointGetPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
) {
    return GetWithPromise(routes.Phases, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
    });
}

