import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FLAGS } from '../constants/flags';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import FindResponse from '../responses/Find';
import { Find2EndpointGetHook } from '../routes/Find';
import { Join2EndpointPostPromise } from '../routes/Join';

interface Props {
    translations: any;
    updateCourses: () => void;
    setCourse: (course: string) => void;
}

function CourseSwitcherPlain(props: Props) {
    const [findState, setFindState] = useState<FindResponse | EmptyResponseType>(EmptyResponse);

    const findResponse = Find2EndpointGetHook();

    useEffect(() => {
        if (findResponse.data) {
            setFindState(findResponse);
        }
    }, [findResponse.data]);

    const addCourse = (language: string) => {
        const promise = Join2EndpointPostPromise('', language);

        promise.then((response) => {
            if (response.status === 200) {
                props.updateCourses();
                localStorage.setItem('course', language);
            }
        });
    };

    const ucFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    return (
        <Grid container spacing={2}>
            {findState.status === 200 && findState.data.languages.map((language: string) => (
                <Grid item xs={12} sm={6} md={4}>
                    <Box
                        key={language}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingY: 2,
                            userSelect: 'none',
                            boxShadow: 2,
                            height: 200,
                            borderRadius: 1,
                            cursor: 'pointer',
                            marginY: 1,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                        onClick={() => {
                            addCourse(language);
                        }}
                    >
                        {flagExists(language) &&
                                <img
                                    src={'/' + language + '.png'}
                                    alt={language}
                                    style={{
                                        width: 50,
                                        borderRadius: 5,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        marginRight: 5,
                                    }}
                                />
                        }
                        <Typography
                            variant='body1'
                            component='p'
                            sx={{
                                textAlign: 'center',
                                paddingX: 1,
                            }}
                        >
                            {ucFirst(language)}
                        </Typography>
                    </Box>
                </Grid>
            )
            )}
        </Grid>
    );
}

export default CourseSwitcherPlain;
