import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function State2EndpointGetHook(
) {
    return GetWithHook(routes.State, {
    });
}

export function State2EndpointGetPromise(
) {
    return GetWithPromise(routes.State, {
    });
}

