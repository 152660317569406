import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function MissionChat2EndpointGetHook(
    param_chatbot: string, 
    param_message: string, 
) {
    return GetWithHook(routes.MissionChat, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

export function MissionChat2EndpointGetPromise(
    param_chatbot: string, 
    param_message: string, 
) {
    return GetWithPromise(routes.MissionChat, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

export function MissionChat2EndpointPostPromise(
    param_chatbot: string, 
    param_message: string, 
) {
    return PostWithPromise(routes.MissionChatPost, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

