import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Courses2EndpointGetHook(
    param_user: string, 
) {
    return GetWithHook(routes.Courses, {
        user: param_user, 
    });
}

export function Courses2EndpointGetPromise(
    param_user: string, 
) {
    return GetWithPromise(routes.Courses, {
        user: param_user, 
    });
}

