import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function GetVocabTranslation2EndpointGetHook(
    param_word: string, 
    param_lection: string, 
    param_direction: string, 
) {
    return GetWithHook(routes.GetVocabTranslation, {
        word: param_word, 
        lection: param_lection, 
        direction: param_direction, 
    });
}

export function GetVocabTranslation2EndpointGetPromise(
    param_word: string, 
    param_lection: string, 
    param_direction: string, 
) {
    return GetWithPromise(routes.GetVocabTranslation, {
        word: param_word, 
        lection: param_lection, 
        direction: param_direction, 
    });
}

