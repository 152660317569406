import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function ListChatbots2EndpointGetHook(
    param_isMission: string, 
    param_course: string, 
) {
    return GetWithHook(routes.ListChatbots, {
        isMission: param_isMission, 
        course: param_course, 
    });
}

export function ListChatbots2EndpointGetPromise(
    param_isMission: string, 
    param_course: string, 
) {
    return GetWithPromise(routes.ListChatbots, {
        isMission: param_isMission, 
        course: param_course, 
    });
}

