import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Streak2EndpointGetHook(
) {
    return GetWithHook(routes.Streak, {
    });
}

export function Streak2EndpointGetPromise(
) {
    return GetWithPromise(routes.Streak, {
    });
}

