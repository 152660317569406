import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import LessonResponse from '../responses/Lesson';
import { Language2EndpointGetHook } from '../routes/Language';
import { Lesson2EndpointGetHook } from '../routes/Lesson';
import ReactMarkdown from 'react-markdown';
import { theme } from '../generals/theme';

interface Props {

}

function Course(props: Props) {
    const { baseLanguage, targetLanguage, lection } = useParams();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [translations, setTranslations] = useState({
        start: '',
        back: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
        }
    }, [translationResponse.data]);

    const [lessonState, setLessonState] = useState<LessonResponse | EmptyResponseType>(EmptyResponse);

    const lessonResponse = Lesson2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '', lection ?? '');

    useEffect(() => {
        if (lessonResponse.data) {
            setLessonState(lessonResponse);
        }
    }, [lessonResponse.data]);

    return (
        <Container maxWidth='md' sx={{ paddingY: 10 }}>
            {lessonState.status === 200 &&
                <Box>
                    <Typography
                        variant='h4'
                        component='h1'
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        {lessonState.data.title}
                    </Typography>
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontSize: { xs: 18, md: 26 },
                        }}
                    >
                        <ReactMarkdown>
                            {lessonState.data.preText}
                        </ReactMarkdown>
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        sx={{
                            position: 'fixed',
                            bottom: isMobile ? '6rem' : '1rem',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            marginTop: '1rem',
                        }}
                        onClick={
                            () => { navigate('/courses/' + baseLanguage + '/' + targetLanguage + '/' + lessonState.data.id + '/lesson'); }
                        }
                    >
                        {translations.start}
                    </Button>
                </Box>
            }
        </Container>
    );
}

export default Course;
