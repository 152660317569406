
interface Props {
    isLogin?: boolean;
    translationState?: any;
    addAction: () => void;
    vocabAction: () => void;
    baseLanguage?: string;
    targetLanguage?: string;
}

export default function FABs(props: Props) {
    return (
        <>
            {props.isLogin &&
                <>
                </>
            }
        </>
    );
}
