import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { ChatbotModel } from '../../models/ChatbotModel';
import Chatbot from './mission/Chatbot';

interface MissionData {
    id: string;
    lection: string;
    name: string;
    description: string;
    goal: string;
    chatbot: ChatbotModel;
}

interface Props {
    data: MissionData;
    next: () => void;
    translations: any;
    baseLanguage: string;
    targetLanguage: string;
}

function Mission(props: Props) {
    const [isCompleted, setIsCompleted] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                justifyContent: 'center',
                padding: { xs: 2, md: 4 },
                height: {
                    xs: 'calc(100vh - 130px)',
                    md: 'calc(100vh - 155px)'
                },
            }}
        >
            <Typography
                variant="h4"
                align="center"
            >
                {props.data.name}
            </Typography>
            <Typography
                variant="body1"
                align="center"
            >
                {props.data.description}
            </Typography>
            <Typography
                variant="body2"
                align="center"
            >
                {props.translations.mission}: {props.data.goal}
            </Typography>
            <Chatbot
                next={() => { setIsCompleted(true); }}
                chatbot={props.data.chatbot.id}
            />
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Button
                    disabled={!isCompleted}
                    onClick={props.next}
                    variant="contained"
                >
                    {props.translations.next}
                </Button>
                <Button
                    onClick={props.next}
                >
                    {props.translations.skip}
                </Button>
            </Box>
        </Box>
    );
}

export default Mission;
