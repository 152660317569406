import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Join2EndpointGetHook(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
) {
    return GetWithHook(routes.Join, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
    });
}

export function Join2EndpointGetPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
) {
    return GetWithPromise(routes.Join, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
    });
}

export function Join2EndpointPostPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
) {
    return PostWithPromise(routes.JoinPost, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
    });
}

