import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Questions2EndpointGetHook(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lection: string, 
    param_isSupported: string, 
) {
    return GetWithHook(routes.Questions, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lection: param_lection, 
        isSupported: param_isSupported, 
    });
}

export function Questions2EndpointGetPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lection: string, 
    param_isSupported: string, 
) {
    return GetWithPromise(routes.Questions, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lection: param_lection, 
        isSupported: param_isSupported, 
    });
}

