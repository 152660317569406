import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function SelectTable2EndpointGetHook(
    param_table: string, 
    param_page: string, 
    param_perPage: string, 
) {
    return GetWithHook(routes.SelectTable, {
        table: param_table, 
        page: param_page, 
        perPage: param_perPage, 
    });
}

export function SelectTable2EndpointGetPromise(
    param_table: string, 
    param_page: string, 
    param_perPage: string, 
) {
    return GetWithPromise(routes.SelectTable, {
        table: param_table, 
        page: param_page, 
        perPage: param_perPage, 
    });
}

