import { useEffect, useState } from 'react';
import { Language2EndpointGetHook } from '../routes/Language';

function useTranslationState<T extends Record<string, string>>(initialState: T): T {
    const [translationState, setTranslationState] = useState<T>(initialState);

    const TranslationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translationState)));

    useEffect(() => {
        if (TranslationResponse.status === 200) {
            localStorage.setItem('languageSet', TranslationResponse.data.userLanguageSetting);
            setTranslationState(prevState => ({ ...prevState, ...TranslationResponse.data.translations }));
        }
    }, [TranslationResponse]);

    return translationState;
}

export default useTranslationState;

