import { Avatar, Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from '../generals/theme';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import PhasesResponse from '../responses/Phases';
import { Language2EndpointGetHook } from '../routes/Language';
import { Phases2EndpointGetHook } from '../routes/Phases';

function VocabsOverview() {
    const { baseLanguage, targetLanguage } = useParams();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [translations, setTranslations] = useState({
        noVocabs: '',
        iwascorrect: '',
        iwaswrong: '',
        back: '',
        start: '',
        total: '',
        phase1: '',
        phase2: '',
        phase3: '',
        phase4: '',
        phase5: '',
        phase6: '',
        due: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
        }
    }, [translationsResponse.data]);

    const [phasesState, setPhasesState] = useState<PhasesResponse | EmptyResponseType>(EmptyResponse);

    const phasesResponse = Phases2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '');

    useEffect(() => {
        if (phasesResponse.data) {
            setPhasesState(phasesResponse);
        }
    }, [phasesResponse.data]);

    const getCountPerPhase = (phase: string) => {
        if (phasesState.status === 200) {
            return phasesState.data.countPerPhase[phase] ?? 0;
        }

        return 0;
    };

    const total = () => {
        let total = 0;

        if (phasesState.status === 200) {
            Object.keys(phasesState.data.countPerPhase).forEach((phase) => {
                total += phasesState.data.countPerPhase[phase];
            });
        }

        return total;
    };

    return (
        <Container
            maxWidth='sm'
        >
            {phasesState.status === 200 &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100vw',
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: 6,
                        }}
                    >
                        {translations.due.replace('[count]', phasesState.data.dueCount)}
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.total}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: total() > 0 ? 'pink' : 'lightgreen',
                                    }}
                                >
                                    {total()}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase1}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('1')}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase2}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('2')}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase3}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('3')}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase4}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('4')}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase5}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('5')}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.phase6}
                                </Typography>
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {getCountPerPhase('6')}
                                </Avatar>
                            </Box>
                        </Grid>
                    </Grid>
                    {phasesState.data.dueCount > 0 &&
                        <Button
                            variant='contained'
                            onClick={() => navigate('/vocab/' + baseLanguage + '/' + targetLanguage + '/train')}
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            {translations.start}
                        </Button>
                    }
                </Box>
            }
        </Container>
    );
}

export default VocabsOverview;

