import { Box, Modal } from '@mui/material';
import { modalStyle } from '../constants/modalstyle';
import CloseButton from './closebutton';
import ModalTitle from './ModalTitle';

interface Props {
    open?: boolean;
    close?: () => void;
    children: any;
    sx?: any;
    title?: string;
}

function LairnerModal(props: Props) {
    const modalStyleWithSx = props.sx
        ? { ...modalStyle, ...props.sx }
        : modalStyle;

    return (
        <div>
            <Modal
                open={props.open ?? true}
                onClose={props.close ?? (() => { })}
                aria-labelledby={Math.random().toString()}
                aria-describedby={Math.random().toString()}
            >
                <Box
                    sx={modalStyleWithSx}
                >
                    {props.title &&
                        <ModalTitle
                            title={props.title}
                        />
                    }
                    {props.close &&
                        <CloseButton
                            close={props.close}
                        />
                    }
                    <Box
                        sx={{
                            overflowY: 'auto',
                            height: '100%',
                        }}
                    >
                        {props.children}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default LairnerModal;

