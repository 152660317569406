import { Mic } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { convertLanguageNameToCode } from '../../../generals/speak';
import { theme } from '../../../generals/theme';

interface Props {
    transcript: string;
    setTranscript: (transcript: string) => void;
    language: string;
    correct: boolean;
    disabled: boolean;
}

const SpeechRecognition = (window as any).webkitSpeechRecognition || (window as any).SpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

function SpeechRecognitionComponent(props: Props) {
    const [isListening, setIsListening] = useState(false);

    useEffect(() => {
        if (props.correct) {
            setIsListening(false);

            if (recognition) {
                recognition.stop();
            }
        }
    }, [props.correct]);

    useEffect(() => {
        recognition.continuous = false;
        recognition.interimResults = true;
        recognition.lang = convertLanguageNameToCode(props.language);

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.onresult = async (event: any) => {
            let interimTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    props.setTranscript(event.results[i][0].transcript);
                } else {
                    interimTranscript = event.results[i][0].transcript;
                }
            }
            props.setTranscript(interimTranscript);
        };

        return () => {
            recognition.stop();
        };
    }, []);

    const startListening = () => {
        setIsListening(true);
        recognition.start();
    };

    const stopListening = () => {
        setIsListening(false);
        recognition.stop();
    };

    return (
        <>
            <Button
                variant='contained'
                onClick={isListening ? stopListening : startListening}
                disabled={props.disabled}
                sx={{
                    marginRight: '1rem',
                    cursor: 'pointer',
                    width: 100,
                    height: 80,
                    backgroundColor: isListening
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: isListening
                            ? theme.palette.secondary.dark
                            : theme.palette.primary.dark,
                    },
                }}
            >
                <Mic
                    sx={{
                        fontSize: '3rem',
                    }}
                />
            </Button>
            <TextField
                value={props.transcript}
                sx={{
                    width: 500,
                    maxWidth: '80vw',
                    marginTop: 4,
                    cursor: 'not-allowed',
                }}
                onChange={() => { }}
                rows={3}
                multiline
            />
        </>
    );
}

export default SpeechRecognitionComponent;

