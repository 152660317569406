import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function ContinueVocab2EndpointGetHook(
    param_phase: string, 
    param_correct: string, 
) {
    return GetWithHook(routes.ContinueVocab, {
        phase: param_phase, 
        correct: param_correct, 
    });
}

export function ContinueVocab2EndpointGetPromise(
    param_phase: string, 
    param_correct: string, 
) {
    return GetWithPromise(routes.ContinueVocab, {
        phase: param_phase, 
        correct: param_correct, 
    });
}

export function ContinueVocab2EndpointPostPromise(
    param_phase: string, 
    param_correct: string, 
) {
    return PostWithPromise(routes.ContinueVocabPost, {
        phase: param_phase, 
        correct: param_correct, 
    });
}

