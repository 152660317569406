import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Comments2EndpointGetHook(
    param_question: string, 
) {
    return GetWithHook(routes.Comments, {
        question: param_question, 
    });
}

export function Comments2EndpointGetPromise(
    param_question: string, 
) {
    return GetWithPromise(routes.Comments, {
        question: param_question, 
    });
}

