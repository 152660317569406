import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Comment2EndpointGetHook(
    param_question: string, 
    param_comment: string, 
    param_parent: string, 
) {
    return GetWithHook(routes.Comment, {
        question: param_question, 
        comment: param_comment, 
        parent: param_parent, 
    });
}

export function Comment2EndpointGetPromise(
    param_question: string, 
    param_comment: string, 
    param_parent: string, 
) {
    return GetWithPromise(routes.Comment, {
        question: param_question, 
        comment: param_comment, 
        parent: param_parent, 
    });
}

export function Comment2EndpointPostPromise(
    param_question: string, 
    param_comment: string, 
    param_parent: string, 
) {
    return PostWithPromise(routes.CommentPost, {
        question: param_question, 
        comment: param_comment, 
        parent: param_parent, 
    });
}

