import { Add } from '@mui/icons-material';
import { Box, Button, FormControl, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FLAGS } from '../constants/flags';
import FABs from '../generals/FABs';
import CoursesResponse from '../responses/Courses';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import { Courses2EndpointGetHook, Courses2EndpointGetPromise } from '../routes/Courses';
import { Language2EndpointGetHook } from '../routes/Language';
import AddCourseDialog from './AddCourseDialog';

interface Props {
    course: string;
    setCourse: (course: string) => void;
}

function BetterCourseSwitcher(props: Props) {
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        course: '',
        addCourse: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));
    const [userLanguage, setUserLanguage] = useState('');

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
            setUserLanguage(translationsResponse.userState.userModel.language);
        }
    }, [translationsResponse.data]);

    // Mui dropdown to switch between courses
    const [coursesState, setCoursesState] = useState<CoursesResponse | EmptyResponseType>(EmptyResponse);

    const coursesResponse = Courses2EndpointGetHook('');

    useEffect(() => {
        if (coursesResponse.data) {
            setCoursesState(coursesResponse);
        }
    }, [coursesResponse.data]);

    const handleAddCourse = () => {
        showAddCourseDialog();
    };

    const changeCourse = (course: string) => {
        if (course === props.course) {
            handleClose();
            return;
        }

        localStorage.setItem('course', course);
        props.setCourse(course);
    };


    useEffect(() => {
        if (props.course === '') {
            if (coursesState.status === 200 && coursesState.data.courses.length > 0) {
                localStorage.setItem('course', coursesState.data.courses[0].id);
                props.setCourse(coursesState.data.courses[0].id);
                window.location.reload();
            }
        }
        if (coursesState.status === 200 && coursesState.data.courses.length > 0) {
            if (!coursesState.data.courses.some((course: any) => course.id === props.course)) {
                localStorage.setItem('course', coursesState.data.courses[0].id);
                props.setCourse(coursesState.data.courses[0].id);
                window.location.reload();
            }
        }
    }, [coursesState.status, coursesState.data, props.course, props.setCourse]);

    const [showAddCourseDialogState, setShowAddCourseDialogState] = useState(false);

    const showAddCourseDialog = () => {
        setShowAddCourseDialogState(true);
    };

    const translateCourseIntoBaseLanguage = (course: any): string => {
        // Course looks like this: baseLanguage__-__targetLanguage
        const split = course.split('__-__');
        return split[0];
    };

    const translateCourseIntoTargetLanguage = (course: any): string => {
        // Course looks like this: baseLanguage__-__targetLanguage
        const split = course.split('__-__');
        return split[1] || '';
    };

    const openVocab = () => {
        navigate('/vocab/' + translateCourseIntoBaseLanguage(props.course) + '/' + translateCourseIntoTargetLanguage(props.course));
    };

    const updateCourses = () => {
        const promise = Courses2EndpointGetPromise('');

        promise.then((response) => {
            if (response.status === 200) {
                window.location.reload();
            }
        });
    };

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const removeCurrentCourse = () => {
        // Return the coursesState.data.courses, but without the current course
        if (coursesState.status === 200) {
            return coursesState.data.courses.filter((course: any) => course.id !== props.course);
        }

        return [];
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
            }}
        >
            {coursesState.status === 200 && coursesState.data.courses.length > 0 &&
                <FormControl fullWidth>
                    <Button
                        id="select"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{
                            marginY: 1,
                            fontSize: 20,
                        }}
                    >
                        {flagExists(translateCourseIntoTargetLanguage(props.course)) ? (
                            <img
                                src={'/' + translateCourseIntoTargetLanguage(props.course) + '.png'}
                                alt={translateCourseIntoTargetLanguage(props.course)}
                                style={{
                                    width: 60,
                                    borderRadius: 5,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        ) : (
                            translateCourseIntoTargetLanguage(props.course)
                        )}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        disableScrollLock
                    >
                        {coursesState.status === 200 && coursesState.data.courses.map((course: any) => (
                            <MenuItem
                                key={course.id}
                                onClick={() => {
                                    changeCourse(course.id);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: course.id === props.course ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                {flagExists(course.targetLanguage) ? (
                                    <img
                                        src={'/' + course.targetLanguage + '.png'}
                                        alt={course.targetLanguage}
                                        style={{
                                            width: 60,
                                            borderRadius: 5,
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    />
                                ) : (
                                    course.targetLanguage
                                )}
                            </MenuItem>
                        ))}
                        <MenuItem
                            key="add"
                            onClick={handleAddCourse}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                        >
                            <Add />
                        </MenuItem>
                    </Menu>
                </FormControl>
            }
            {coursesState.status === 200 && coursesState.data.courses.length === 0 &&
                <Button
                    variant="contained"
                    onClick={showAddCourseDialog}
                    sx={{
                        marginY: 1,
                        fontSize: 14,
                    }}
                >
                    {translations.addCourse}
                </Button>
            }
            {showAddCourseDialogState &&
                <AddCourseDialog
                    open={showAddCourseDialogState}
                    setOpen={setShowAddCourseDialogState}
                    updateCourses={updateCourses}
                    course={props.course}
                    language={userLanguage}
                />
            }
            <FABs
                isLogin={true}
                translationState={translations}
                addAction={showAddCourseDialog}
                vocabAction={openVocab}
                baseLanguage={translateCourseIntoBaseLanguage(props.course)}
                targetLanguage={translateCourseIntoTargetLanguage(props.course)}
            />
        </Box>
    );
}

export default BetterCourseSwitcher;

