import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Continue2EndpointGetHook(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lection: string, 
    param_mistakes: string, 
) {
    return GetWithHook(routes.Continue, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lection: param_lection, 
        mistakes: param_mistakes, 
    });
}

export function Continue2EndpointGetPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lection: string, 
    param_mistakes: string, 
) {
    return GetWithPromise(routes.Continue, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lection: param_lection, 
        mistakes: param_mistakes, 
    });
}

export function Continue2EndpointPostPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lection: string, 
    param_mistakes: string, 
) {
    return PostWithPromise(routes.ContinuePost, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lection: param_lection, 
        mistakes: param_mistakes, 
    });
}

