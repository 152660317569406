import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FLAGS } from '../constants/flags';
import LairnerModal from '../generals/LairnerModal';
import { theme } from '../generals/theme';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import FindResponse from '../responses/Find';
import { Find2EndpointGetHook } from '../routes/Find';
import { Join2EndpointPostPromise } from '../routes/Join';
import { Language2EndpointGetHook } from '../routes/Language';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    updateCourses: () => void;
    language: string;
    course: string;
}

function AddCourseDialog(props: Props) {
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        addCourse: '',
        noCourses: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
        }
    }, [translationResponse.data]);

    const [findState, setFindState] = useState<FindResponse | EmptyResponseType>(EmptyResponse);

    const findResponse = Find2EndpointGetHook();

    useEffect(() => {
        if (findResponse.data) {
            setFindState(findResponse);
        }
    }, [findResponse.data]);

    const ucFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

    const translateLanguagesIntoCourse = (baseLanguage: string, targetLanguage: string) => baseLanguage + '__-__' + targetLanguage;

    const addCourse = (language: string) => {
        const promise = Join2EndpointPostPromise(props.language, language);

        promise.then((response) => {
            if (response.status === 200) {
                localStorage.setItem('course', translateLanguagesIntoCourse(props.language, language));
                props.updateCourses();
                props.setOpen(false);

                navigate('/courses/' + props.language + '/' + language + '/intro/intro');
            }
        });
    };

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    const translateCourseIntoTargetLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[1];
    };

    return (
        <LairnerModal
            open={props.open}
            close={() => {
                props.setOpen(false);
            }}
            title={translations.addCourse}
        >
            <Grid
                container
                spacing={2}
            >
                {findState.status === 200 &&
                    findState.data.languages.map((language: string) => (
                        <Grid item xs={6}>
                            <Box
                                key={language}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: 1,
                                    userSelect: 'none',
                                    boxShadow: 0,
                                    backgroundColor: theme.palette.grey[100],
                                    height: 50,
                                    borderRadius: 2,
                                    cursor: 'pointer',
                                    transition: 'all 0.2s ease-in-out',
                                }}
                                onClick={() => {
                                    addCourse(language);
                                }}
                            >
                                {flagExists(language) &&
                                    <img
                                        src={'/' + language + '.png'}
                                        style={{
                                            width: 45,
                                            borderRadius: 5,
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                            marginRight: 5,
                                        }}
                                    />
                                }
                                <Typography
                                    variant='body2'
                                    component='p'
                                    sx={{
                                        textAlign: 'center',
                                        paddingX: 1,
                                    }}
                                >
                                    {ucFirst(language)}
                                </Typography>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
            {findState.status === 200 && findState.data.languages.length === 0 &&
                <Typography
                    variant='body1'
                    component='p'
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {translations.noCourses}
                </Typography>
            }
        </LairnerModal>
    );
}

export default AddCourseDialog;
