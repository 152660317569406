import { Box, Button, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Char from '../generals/Char';
import { theme } from '../generals/theme';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import PhasesResponse from '../responses/Phases';
import { ContinueVocab2EndpointPostPromise } from '../routes/ContinueVocab';
import { Language2EndpointGetHook } from '../routes/Language';
import { Phases2EndpointGetHook } from '../routes/Phases';
import VocabsOverview from './VocabsOverview';

interface Props {
    speak: (text: string, language?: string) => void;
}

function Vocabs(props: Props) {
    const { baseLanguage, targetLanguage } = useParams();
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        noVocabs: '',
        iwascorrect: '',
        iwaswrong: '',
        back: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
        }
    }, [translationsResponse.data]);

    const [phasesState, setPhasesState] = useState<PhasesResponse | EmptyResponseType>(EmptyResponse);

    const phasesResponse = Phases2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '');

    useEffect(() => {
        if (phasesResponse.data) {
            setPhasesState(phasesResponse);
        }
    }, [phasesResponse.data]);

    const [phaseIndex, setPhaseIndex] = useState(0);

    const [showSolution, setShowSolution] = useState(false);

    const correctAudio = new Audio('/correct_piano.mp3');
    const incorrectAudio = new Audio('/incorrect_piano.mp3');

    const iDidCorrect = (phase: string) => {
        const promise = ContinueVocab2EndpointPostPromise(phase, 'true');
        setShowSolution(false);
        setPhaseIndex(phaseIndex + 1);
        correctAudio.play();

        promise.then(() => {
        });
    };

    const iDidWrong = (phase: string, index: number) => {
        const promise = ContinueVocab2EndpointPostPromise(phase, 'false');
        incorrectAudio.play();

        setShowSolution(false);
        setPhaseIndex(phaseIndex + 1);

        promise.then(() => {
            reAddPhase(index);
        });
    };

    const reAddPhase = (phaseIndex: number) => {
        // Keep the current phases, but add it again to the end of the list.
        const phase = phasesState.data.phases[phaseIndex];

        const newPhases = phasesState.data.phases;
        newPhases.push(phase);

        setPhasesState({
            ...phasesState,
            data: {
                ...phasesState.data,
                phases: newPhases,
            },
        });
    };

    return (
        <Container
            maxWidth='sm'
        >
            {phasesState.status === 200 && (phasesState.data.phases.length === 0 || phaseIndex >= phasesState.data.phases.length) &&
                <VocabsOverview
                />
            }
            {phasesState.status === 200 && phaseIndex < phasesState.data.phases.length &&
                <Box
                    sx={{
                        paddingY: 10,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: 2,
                            cursor: !showSolution ? 'pointer' : 'default',
                            height: 350,
                            userSelect: 'none',
                        }}
                        onClick={() => {
                            if (!showSolution) {
                                props.speak(phasesState.data.phases[phaseIndex].matchingWord.targetWord, targetLanguage);
                                setShowSolution(true);
                            }
                        }}
                    >
                        {!showSolution &&
                            <Typography
                                variant='h4'
                                component='h1'
                                gutterBottom
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                <Char
                                    vocab={phasesState.data.phases[phaseIndex].matchingWord}
                                    language={targetLanguage!}
                                    height='50px'
                                    width='50px'
                                />
                                {phasesState.data.phases[phaseIndex].matchingWord.baseWord}
                            </Typography>
                        }
                        {showSolution &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    component='h1'
                                    gutterBottom
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <Char
                                        vocab={phasesState.data.phases[phaseIndex].matchingWord}
                                        language={targetLanguage!}
                                        height='50px'
                                        width='50px'
                                    />
                                    {phasesState.data.phases[phaseIndex].matchingWord.targetWord}
                                </Typography>
                                {phasesState.data.phases[phaseIndex].matchingWord.transliteration !== '' &&
                                    <Typography
                                        variant='h6'
                                        component='h2'
                                        gutterBottom
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        [{phasesState.data.phases[phaseIndex].matchingWord.transliteration}]
                                    </Typography>
                                }
                                <Box
                                    sx={{
                                        marginTop: 6,
                                    }}
                                >
                                    <Button
                                        onClick={() => iDidCorrect(phasesState.data.phases[phaseIndex].id)}
                                        sx={{
                                            backgroundColor: 'green',
                                            color: 'white',
                                            margin: 2,
                                            '&:hover': {
                                                backgroundColor: 'lightgreen',
                                            },
                                        }}
                                    >
                                        {translations.iwascorrect}
                                    </Button>
                                    <Button
                                        onClick={() => iDidWrong(phasesState.data.phases[phaseIndex].id, phaseIndex)}
                                        sx={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            margin: 2,
                                            '&:hover': {
                                                backgroundColor: 'pink',
                                            },
                                        }}
                                    >
                                        {translations.iwaswrong}
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </Container>
    );
}

export default Vocabs;
