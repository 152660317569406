import { VolumeUp } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import AnswerInfoResponse from '../../responses/AnswerInfo';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import { AnswerInfo2EndpointGetHook, AnswerInfo2EndpointGetPromise } from '../../routes/AnswerInfo';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Box, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { TTS } from '../../generals/speak';
import { theme } from '../../generals/theme';
import { QuestionModel } from '../../models/QuestionModel';
import AnswerButton from '../AnswerButton';
import QuestionText from './QuestionText';
import useIsMobile from '../../constants/useIsMobile';
import Char from '../../generals/Char';
import SelectedChips from './SelectedChips';
import SelectableChips from './SelectableChips';

interface ReverseQuestion extends QuestionModel {
    syllables: any[];
    answer: string;
}

interface Props {
    question: ReverseQuestion;
    index: number;
    answer: string;
    originalCount: number;
    answerDisabledState: boolean;
    setCurrentAnswer: (answer: string) => void;
    handleAnswer: () => void;
    handleAddChip: (id: string, speak?: boolean) => void;
    handleDeleteChip: (id: string) => void;
    selectedChips: string[];
    chipOrTextState: string;
    setChipOrTextState: any;
    setSelectedChips: (chips: string[]) => void;
    baseLanguage: string;
    targetLanguage: string;
    speak: (text: string, language?: string) => void;
    convertChipToSyllable: (id: string) => string;
    contentPosition: any;
    translations: any;
    previousMistakes: string[];
    lection: string;
    skipQuestion: () => void;
}

function ReverseQuestion(props: Props) {
    const isMobile = useIsMobile();

    useEffect(() => {
        setTimeout(() => {
            TTS(props.question.question, props.targetLanguage);
        }, 100);
    }, [props.question]);

    const [answerInfoState, setAnswerInfoState] = useState<AnswerInfoResponse | EmptyResponseType>(EmptyResponse);

    const answerInfoResponse = AnswerInfo2EndpointGetHook(props.question.id);

    useEffect(() => {
        if (answerInfoResponse.data) {
            setAnswerInfoState(answerInfoResponse);
        }
    }, [answerInfoResponse.data]);

    useEffect(() => {
        setShowContent(false);
        const promise = AnswerInfo2EndpointGetPromise(props.question.id);

        promise.then((response) => {
            setAnswerInfoState(response);
        });
    }, [props.question.id]);

    const [showContent, setShowContent] = useState<boolean>(false);
    const [isAnswered, setIsAnswered] = useState<boolean>(false);
    useEffect(() => {
        setIsAnswered(false);
    }, [props.question]);

    const handleAnswer = () => {
        setShowContent(true);
        setIsAnswered(true);
        props.handleAnswer();
    };

    const removeFirstTwoLines = (text: string) => {
        const lines = text.split('\n');

        if (lines[0].startsWith('#') && lines.length > 2) {
            return text.split('\n').slice(2).join('\n');
        }

        return text;
    };


    return (
        <Box
            sx={props.contentPosition}
        >
            {answerInfoState.status === 200 && !isMobile &&
                <Box
                    sx={{
                        position: 'absolute',
                        right: 35,
                        top: 50,
                        width: '16vw',
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: 2,
                        textAlign: 'left',
                        boxShadow: 0,
                        px: 2,
                        filter: showContent ? 'none' : 'blur(7px)',
                    }}
                    onClick={() => setShowContent(!showContent)}
                >
                    <ReactMarkdown>
                        {removeFirstTwoLines(answerInfoState.data.info)}
                    </ReactMarkdown>
                </Box>
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    width: '100%',
                }}
            >
                {props.previousMistakes.length > 0 && props.previousMistakes.includes(props.question.id) && props.originalCount < props.index &&
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        color='error'
                    >
                        {props.translations.previousMistake}
                    </Typography>
                }
                <Typography
                    variant="h4"
                    gutterBottom
                    fontWeight='bold'
                >
                    {props.translations.translation}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Char
                        language={props.targetLanguage}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0.1px solid #c4c4c4',
                            borderRadius: 4,
                            backgroundColor: theme.palette.grey[50],
                            paddingY: 2,
                            paddingLeft: 2,
                            paddingRight: 4,
                            ml: 2,
                            // Add a triangle to the left of the box
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: '-10px',
                                top: '25%',
                                width: 0,
                                height: 0,
                                borderStyle: 'solid',
                                borderWidth: '10px 10px 10px 0',
                                borderColor: 'transparent ' + theme.palette.grey[200] + ' transparent transparent',
                                transform: 'translateY(-50%)',
                            },
                        }}
                    >
                        <IconButton
                            onClick={
                                () => { TTS(props.question.question, props.targetLanguage); }
                            }
                            color='primary'
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <VolumeUp />
                        </IconButton>
                        <QuestionText
                            question={props.question.question}
                            questionId={props.question.id}
                            lection={props.lection}
                            direction='base'
                            entireTranslation={props.question.answer}
                            language={props.targetLanguage}
                            showTranslation={isAnswered}
                        />
                    </Box>
                </Box>
            </Box>
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectedChips
                        selectedChips={props.selectedChips}
                        convertChipToSyllable={props.convertChipToSyllable}
                        setSelectedChips={props.setSelectedChips}
                        handleDeleteChip={props.handleDeleteChip}
                        deletable={true}
                        draggable={true}
                        handleAddChip={(id: string) => props.handleAddChip(id, false)}
                    />
                }
                {props.chipOrTextState === 'text' &&
                    <TextField
                        fullWidth
                        variant='standard'
                        label={props.translations.answer}
                        value={props.answer}
                        onChange={(event) => props.setCurrentAnswer(event.target.value)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: '1px solid ' + theme.palette.divider,
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: '1px solid ' + theme.palette.divider,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: '1px solid ' + theme.palette.divider,
                            },
                            marginTop: 2,
                            '& .MuiInputBase-input': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                }
            </FormControl>
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectableChips
                        handleDeleteChip={props.handleDeleteChip}
                        handleAddChip={(id: string) => props.handleAddChip(id, false)}
                        selectedChips={props.selectedChips}
                        question={props.question}
                        answerDisabledState={props.answerDisabledState}
                    />
                }
            </FormControl>
            <AnswerButton
                answer={handleAnswer}
                currentAnswer={props.answer}
                translations={props.translations}
                baseLanguage={props.baseLanguage}
                targetLanguage={props.targetLanguage}
                speak={props.speak}
                skipQuestion={props.skipQuestion}
                isReverse={true}
                correctAnswer={props.question.answer}
            />
        </Box >
    );
}

export default ReverseQuestion;
