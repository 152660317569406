import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from './theme';

interface ModalTitleProps {
    title: string;
    sx?: any;
}

function ModalTitle(props: ModalTitleProps) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                p: 1,
                zIndex: 1000,
                textAlign: 'left',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.primary.dark,
                    ...props.sx,
                }}
            >
                {props.title}
            </Typography>
        </Box>
    );
}

export default ModalTitle;


