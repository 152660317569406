import { useEffect, useState } from 'react';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';

function useEndpoint<T>(response: any): [T, any] {
    const [responseState, setResponseState] = useState<T | EmptyResponseType>(EmptyResponse);

    useEffect(() => {
        if (response) {
            setResponseState(response);
        }
    }, [response]);

    return [responseState as T, setResponseState];
}

export default useEndpoint;

