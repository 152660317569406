import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Chat2EndpointGetHook(
    param_chatbot: string, 
    param_message: string, 
) {
    return GetWithHook(routes.Chat, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

export function Chat2EndpointGetPromise(
    param_chatbot: string, 
    param_message: string, 
) {
    return GetWithPromise(routes.Chat, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

export function Chat2EndpointPostPromise(
    param_chatbot: string, 
    param_message: string, 
) {
    return PostWithPromise(routes.ChatPost, {
        chatbot: param_chatbot, 
        message: param_message, 
    });
}

