declare global {
    interface Window {
        audios: HTMLAudioElement[];
    }
}

window.audios = [];

export const getAllAudios = () => window.audios;

export const stopAllAudios = () => {
    window.audios.forEach((audio: HTMLAudioElement) => {
        audio.pause();
    });

    window.audios = [];
};

export const addAudio = (audio: HTMLAudioElement) => {
    window.audios.push(audio);
};

export const removeAllNonPlayingAudios = () => {
    window.audios = window.audios.filter((audio: HTMLAudioElement) => audio.played.length > 0);
};
