import { Add } from '@mui/icons-material';
import { Button, FormControl, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { CreateCourse2EndpointPostPromise } from '../routes/CreateCourse';
import LairnerModal from './LairnerModal';

function CreateCourse() {
    const [open, setOpen] = useState(false);

    const [baseLanguage, setBaseLanguage] = useState('english');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [lectionCount, setLectionCount] = useState(5);

    const createCourse = () => {
        const promise = CreateCourse2EndpointPostPromise(
            baseLanguage,
            targetLanguage,
            lectionCount.toString(),
            '0',
            'false',
        );

        promise.then((response) => {
            if (response.status === 200) {
                setOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                color="primary"
            >
                <Add />
            </IconButton>
            <LairnerModal
                open={open}
                close={() => setOpen(false)}
                title="Create Course"
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Base Language"
                        value={baseLanguage}
                        onChange={(event) => setBaseLanguage(event.target.value)}

                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Target Language"
                        value={targetLanguage}
                        onChange={(event) => setTargetLanguage(event.target.value)}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Lection Count"
                        value={lectionCount}
                        type="number"
                        onChange={(event) => setLectionCount(parseInt(event.target.value))}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Button
                        onClick={createCourse}
                        color="primary"
                        variant="contained"
                    >
                        <Add />
                    </Button>
                </FormControl>
            </LairnerModal>
        </>
    );
}

export default CreateCourse;
