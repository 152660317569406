interface Props {
    src: string;
    width?: number;
    height?: number;
    alt?: string;
    position?: any;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    boxShadow?: number;
    borderRadius?: number;
    styles?: any;
    onClick?: () => void;
}

export default function Image({
    src,
    width = 100,
    height = 100,
    alt = 'Lairner',
    position = 'relative',
    top = 0,
    left = 0,
    right = 0,
    bottom = 0,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    boxShadow = 0,
    borderRadius = 0,
    styles = {},
    onClick,
}: Props) {
    const style = {
        position: position,
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight,
        borderRadius: borderRadius,
        boxShadow: '0px 0px ' + boxShadow + 'px 0px rgba(0,0,0,0.75)',
        ...styles,
    };

    return (
        <img
            style={style}
            src={src}
            alt={alt}
            width={width}
            height={height}
            onClick={onClick}
        />
    );
}
