import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import useIsMobile from '../../constants/useIsMobile';
import AnswerInfoResponse from '../../responses/AnswerInfo';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import { AnswerInfo2EndpointGetHook, AnswerInfo2EndpointGetPromise } from '../../routes/AnswerInfo';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from '../../generals/theme';
import { QuestionModel } from '../../models/QuestionModel';
import SpeechRecognitionComponent from './microphone/stt';

interface MicrophoneQuestion extends QuestionModel {
    syllables: any[];
    answer: string;
    translation: string;
}

interface Props {
    question: MicrophoneQuestion;
    index: number;
    answer: string;
    originalCount: number;
    answerDisabledState: boolean;
    setCurrentAnswer: (answer: string) => void;
    handleAnswer: (accept?: boolean) => void;
    handleAddChip: (id: string) => void;
    handleDeleteChip: (id: string) => void;
    selectedChips: string[];
    setSelectedChips: (chips: string[]) => void;
    chipOrTextState: string;
    setChipOrTextState: any;
    baseLanguage: string;
    targetLanguage: string;
    speak: (text: string, language?: string) => void;
    convertChipToSyllable: (id: string) => string;
    contentPosition: any;
    translations: any;
    previousMistakes: string[];
    lection: string;
    v2Next: () => void;
}

function MicrophoneQuestion(props: Props) {
    const [answerInfoState, setAnswerInfoState] = useState<AnswerInfoResponse | EmptyResponseType>(EmptyResponse);
    const [showAcceptAsCorrect, setShowAcceptAsCorrect] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const isMobile = useIsMobile();

    const answerInfoResponse = AnswerInfo2EndpointGetHook(props.question.id);

    useEffect(() => {
        if (answerInfoResponse.data) {
            setAnswerInfoState(answerInfoResponse);
        }
    }, [answerInfoResponse.data]);

    useEffect(() => {
        setShowContent(false);
        setDisabled(false);
        setCorrect(false);
        const promise = AnswerInfo2EndpointGetPromise(props.question.id);

        promise.then((response) => {
            setAnswerInfoState(response);
        });
    }, [props.question.id, props.index]);

    const [showContent, setShowContent] = useState<boolean>(false);
    const [correct, setCorrect] = useState<boolean>(false);

    const handleAnswer = () => {
        setShowContent(true);
        setDisabled(true);
        setCorrect(true);
        props.handleAnswer(true);
    };

    const levenshteinDistance = (a: string, b: string) => {
        const matrix: number[][] = [];

        for (let i = 0; i <= a.length; i++) {
            matrix[i] = [];
            for (let j = 0; j <= b.length; j++) {
                matrix[i][j] = 0;
            }
        }

        for (let i = 0; i <= a.length; i++) {
            matrix[i][0] = i;
        }

        for (let j = 0; j <= b.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
                );
            }
        }

        return matrix[a.length][b.length];
    };

    useEffect(() => {
        if (props.answer !== '') {
            if (props.answer.toLowerCase() === props.question.answer.toLowerCase()) {
                handleAnswer();
                return;
            }

            if (levenshteinDistance(props.answer.toLowerCase(), props.question.answer.toLowerCase()) <= 2) {
                setShowAcceptAsCorrect(true);
            }
        }
    }, [props.answer]);


    const removeFirstTwoLines = (text: string) => {
        const lines = text.split('\n');

        if (lines[0].startsWith('#') && lines.length > 2) {
            return text.split('\n').slice(2).join('\n');
        }

        return text;
    };


    return (
        <Box
            sx={props.contentPosition}
        >
            {answerInfoState.status === 200 && !isMobile &&
                <Box
                    sx={{
                        position: 'absolute',
                        right: 35,
                        top: 50,
                        width: '16vw',
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: 2,
                        textAlign: 'left',
                        boxShadow: 0,
                        px: 2,
                        filter: showContent ? 'none' : 'blur(7px)',
                    }}
                    onClick={() => setShowContent(!showContent)}
                >
                    <ReactMarkdown>
                        {removeFirstTwoLines(answerInfoState.data.info)}
                    </ReactMarkdown>
                </Box>
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    width: '100%',
                }}
            >
                {props.previousMistakes.length > 0 && props.previousMistakes.includes(props.question.id) && props.originalCount < props.index &&
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        color='error'
                    >
                        {props.translations.previousMistake}
                    </Typography>
                }
                <Typography
                    variant="h4"
                    gutterBottom
                    fontWeight='bold'
                >
                    {props.translations.speak}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        fontWeight='bold'
                    >
                        {props.question.question}
                    </Typography>
                </Box>
            </Box>
            <SpeechRecognitionComponent
                transcript={props.answer}
                setTranscript={props.setCurrentAnswer}
                language={props.targetLanguage}
                correct={correct}
                disabled={disabled}
            />
            {showAcceptAsCorrect &&
                <Button
                    onClick={handleAnswer}
                    variant='contained'
                    sx={{
                        marginTop: 4,
                    }}
                >
                    {props.translations.acceptAsCorrect}
                </Button>
            }
            <Button
                onClick={props.v2Next}
                variant='text'
                disabled={disabled}
                sx={{
                    marginTop: 4,
                }}
            >
                {props.translations.skip}
            </Button>
        </Box>
    );
}

export default MicrophoneQuestion;
