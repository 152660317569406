import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { modalStyle } from '../../constants/modalstyle';
import { Report2EndpointPostPromise } from '../../routes/Report';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    translations: any;
    question: string;
    answer: string;
}

function ReportDialog(props: Props) {
    const [answerShouldBeAccepted, setAnswerShouldBeAccepted] = useState<boolean>(false);
    const [answerIsWrong, setAnswerIsWrong] = useState<boolean>(false);
    const [questionIsWrong, setQuestionIsWrong] = useState<boolean>(false);
    const [other, setOther] = useState<boolean>(false);
    const [otherReason, setOtherReason] = useState<string>('');

    const getReason = () => {
        if (answerShouldBeAccepted) {
            return props.translations.answerShouldBeAccepted;
        } else if (answerIsWrong) {
            return props.translations.answerIsWrong;
        } else if (questionIsWrong) {
            return props.translations.questionIsWrong;
        } else if (other) {
            return otherReason;
        }

        return '';
    };


    const submit = () => {
        const promise = Report2EndpointPostPromise(
            props.question,
            props.answer,
            getReason(),
        );

        promise.then(() => {
            props.setOpen(false);
        });
    };

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    sx={modalStyle}
                >
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                        fontWeight='bold'
                        gutterBottom
                    >
                        {props.translations.report}
                    </Typography>
                    <FormControl component='fieldset'>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answerShouldBeAccepted}
                                        onChange={() => setAnswerShouldBeAccepted(!answerShouldBeAccepted)}
                                    />
                                }
                                label={props.translations.answerShouldBeAccepted}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answerIsWrong}
                                        onChange={() => setAnswerIsWrong(!answerIsWrong)}
                                    />
                                }
                                label={props.translations.answerIsWrong}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={questionIsWrong}
                                        onChange={() => setQuestionIsWrong(!questionIsWrong)}
                                    />
                                }
                                label={props.translations.questionIsWrong}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={other}
                                        onChange={() => setOther(!other)}
                                    />
                                }
                                label={props.translations.other}
                            />
                            {other &&
                                <TextField
                                    label={props.translations.other}
                                    value={otherReason}
                                    onChange={(event) => setOtherReason(event.target.value)}
                                />
                            }
                        </FormGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={submit}
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            {props.translations.report}
                        </Button>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}

export default ReportDialog;
