import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Logout2EndpointGetHook(
) {
    return GetWithHook(routes.Logout, {
    });
}

export function Logout2EndpointGetPromise(
) {
    return GetWithPromise(routes.Logout, {
    });
}

export function Logout2EndpointPostPromise(
) {
    return PostWithPromise(routes.LogoutPost, {
    });
}

