import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function TTS2EndpointGetHook(
    param_text: string, 
    param_language: string, 
) {
    return GetWithHook(routes.TTS, {
        text: param_text, 
        language: param_language, 
    });
}

export function TTS2EndpointGetPromise(
    param_text: string, 
    param_language: string, 
) {
    return GetWithPromise(routes.TTS, {
        text: param_text, 
        language: param_language, 
    });
}

