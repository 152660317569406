import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Find2EndpointGetHook(
) {
    return GetWithHook(routes.Find, {
    });
}

export function Find2EndpointGetPromise(
) {
    return GetWithPromise(routes.Find, {
    });
}

