import { useEffect, useState } from 'react';
import { theme } from '../generals/theme';

const useIsMobile = () => {
    // Initial value for isMobile is based on the initial window width
    const [isMobile, setIsMobile] = useState(window.innerWidth < theme.breakpoints.values.md);

    useEffect(() => {
        // Handler to update the state whenever the window is resized
        const handleResize = () => {
            setIsMobile(window.innerWidth < theme.breakpoints.values.md);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);  // The empty dependency array ensures this useEffect runs once when the component mounts, and the cleanup runs when it unmounts

    return isMobile;
};

export default useIsMobile;
