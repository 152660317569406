import { Check, KeyboardReturn, Star } from '@mui/icons-material';
import { Box, ButtonBase, Card, CardContent, CardMedia, LinearProgress, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from '../generals/theme';
import { getHost } from '../modules/api/client';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import LessonResponse from '../responses/Lesson';
import { Lesson2EndpointGetHook } from '../routes/Lesson';

interface Props {
    lection: string;
    baseLanguage: string;
    targetLanguage: string;
    isAvailable: boolean;
    isCompleted: boolean;
    completion: number;
    level: string;
    translations: any;
    parts: number;
}

function LectionCard(props: Props) {
    const isMobile = window.innerWidth <= 600;

    const navigate = useNavigate();

    const [lessonState, setLessonState] = useState<LessonResponse | EmptyResponseType>(EmptyResponse);

    const lessonResponse = Lesson2EndpointGetHook(props.baseLanguage ?? '', props.targetLanguage ?? '', props.lection);

    useEffect(() => {
        if (lessonResponse.data) {
            setLessonState(lessonResponse);
        }
    }, [lessonResponse.data]);

    const getProcentualProgress = (): number => {
        if (!props.isAvailable) {
            return 0;
        }

        if (lessonState.status !== 200) {
            return 0;
        }

        const progress = ((lessonState.data.completion) / props.parts) * 100;

        return progress;
    };

    const createLabel = (): string => {
        if (lessonState.status !== 200) {
            return '0';
        }

        if (!props.isAvailable) {
            return '';
        }

        if (lessonState.data.completion > props.parts) {
            return '';
        }

        if (lessonState.data.completion === props.parts) {
            return props.translations.recap;
        }

        return '';
    };

    const getStarAmount = (): number => {
        const difficultyEstimate = lessonState.data.difficultyEstimate;
        const difficultyCalculation = lessonState.data.difficultyCalculation;

        if (difficultyCalculation === 0) {
            return difficultyEstimate;
        }

        return difficultyCalculation;
    };

    const generateTooltipText = (): string => {
        if (lessonState.status !== 200) {
            return '';
        }

        if (!props.isAvailable) {
            return props.translations.thisLessonIsLocked;
        }

        if (lessonState.data.completion > props.parts) {
            return props.translations.thisLessonIsCompleted;
        }

        if (lessonState.data.completion === props.parts) {
            return props.translations.youAreRecappingThisLesson;
        }

        return props.translations.thisLessonIsAvailable.replace('[count]', lessonState.data.completion + 1).replace('[total]', props.parts);
    };

    /*
        useEffect(() => {
            if (props.isAvailable && !props.isCompleted) {
                const handleKeyDown = (event: KeyboardEvent) => {
                    if (event.key === 'Enter') {
                        navigate('/courses/' + props.baseLanguage + '/' + props.targetLanguage + '/' + props.lection + '/lesson');
                    }
                };
    
                document.addEventListener('keydown', handleKeyDown);
    
                return () => {
                    document.removeEventListener('keydown', handleKeyDown);
                };
            }
        }, [props.isAvailable, props.isCompleted, props.baseLanguage, props.targetLanguage, props.lection, navigate]);
        */

    return (
        <Tooltip
            title={generateTooltipText()}
            placement={isMobile ? 'top' : 'right'}
        >
            <ButtonBase
                onClick={() => {
                    if (props.isAvailable) {
                        navigate('/courses/' + props.baseLanguage + '/' + props.targetLanguage + '/' + props.lection + '/lesson');
                    }
                }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile ? '100%' : '55%',
                    userSelect: 'none',
                    transition: 'transform 0.2s',
                    opacity: props.isAvailable ? 1 : 0.5,
                    cursor: props.isAvailable ? 'pointer' : 'not-allowed',
                    border: props.isCompleted
                        ? '0.1px solid rgba(0, 0, 0, 0.2)'
                        : (
                            props.isAvailable
                                ? (lessonState.data.completion === props.parts
                                    ? '1.3px solid rgba(0, 200, 200, 1)'
                                    : '1px solid rgba(0, 0, 200, 0.5)'
                                )
                                : '1px solid rgba(0, 0, 0, 0.05)'
                        ),
                    borderRadius: 2,
                    marginBottom: 1,
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                        boxShadow: 'none',
                        backgroundColor: props.isAvailable
                            ? (props.isCompleted
                                ? 'rgba(0, 180, 0, 0.02)'
                                : (lessonState.data.completion === props.parts
                                    ? 'rgba(0, 200, 200, 0.1)'
                                    : 'rgba(0, 0, 255, 0.05)'
                                )
                            )
                            : theme.palette.grey[200],
                    }}
                >
                    {lessonState.status === 200 &&
                        <Box
                            sx={{
                                display: 'flex',
                                height: '100%',
                            }}
                        >
                            {lessonState.data.image !== undefined && lessonState.data.image !== '' &&
                                <CardMedia
                                    sx={{
                                        position: 'relative',
                                        width: { md: 150, xs: 100 },
                                        height: 'auto',
                                        backgroundPosition: 'center',
                                        objectFit: 'contain',
                                    }}
                                    image={getHost() + '/image.php?file=' + lessonState.data.image}
                                />
                            }
                            <CardContent
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexGrow: 1
                                }}
                            >
                                <Box>
                                    <Typography
                                        gutterBottom
                                        variant={isMobile ? 'subtitle1' : 'h5'}
                                        component='h2'
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {lessonState.data.title}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {Array.from({ length: getStarAmount() }).map((_, index) => (
                                            <Star
                                                key={index}
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        ))}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {createLabel()}
                                        {!props.isCompleted && props.isAvailable && false &&
                                            <KeyboardReturn
                                                sx={{
                                                    fontSize: 22,
                                                    marginLeft: 1,
                                                }}
                                            />
                                        }
                                    </Typography>
                                </Box>
                                {lessonState.data.completion > props.parts &&
                                    <Check
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            m: 1,
                                            color: theme.palette.success.dark,
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                {props.isAvailable && !props.isCompleted &&
                                    <LinearProgress
                                        variant='determinate'
                                        value={getProcentualProgress()}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            width: 'calc(100% - 2rem)',
                                            height: '0.5rem',
                                            borderRadius: '0.5rem',
                                            marginTop: 1,
                                            marginBottom: 1,
                                        }}
                                    />
                                }
                            </CardContent>
                        </Box>
                    }
                </Card>
            </ButtonBase>
        </Tooltip>
    );
}

export default LectionCard;
