import { Box } from '@mui/material';
import useIsMobile from '../../constants/useIsMobile';
import SelectChip from './SelectChip';
interface Props {
    handleDeleteChip: (id: string) => void;
    handleAddChip: (id: string, speak?: boolean) => void;
    selectedChips: string[];
    question: any;
    answerDisabledState: boolean;
    forceNextChip?: boolean;
}

function SelectableChips(props: Props) {
    const isMobile = useIsMobile();

    // If forceNextChip is true, every chip will be disabled except the first, not selected one.
    const isNextChip = (syllableId: string) => {
        // Iterate over all syllables to get the first not selected one.
        for (const syllable of props.question.syllables) {
            if (!props.selectedChips.includes(syllable.id)) {
                return syllable.id === syllableId;
            }
        }

        return false;
    };

    const isDisabled = (syllableId: string) => {
        if (props.forceNextChip !== true) {
            return false;
        }

        return !isNextChip(syllableId);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                marginTop: '2rem',
                justifyContent: 'center',
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
            }}
            onDrop={(e) => {
                e.preventDefault();

                const chipId = localStorage.getItem('draggedChip');

                if (chipId && !props.selectedChips.includes(chipId)) {
                    props.handleAddChip(chipId);
                } else if (chipId && props.selectedChips.includes(chipId)) {
                    props.handleDeleteChip(chipId);
                }
            }}
        >
            {props.question.syllables.map((syllable: any, index: number) => (
                <SelectChip
                    id={syllable.id}
                    text={syllable.syllable}
                    onClick={() => props.handleAddChip(syllable.id)}
                    onDelete={() => props.handleDeleteChip(syllable.id)}
                    disabled={props.selectedChips.includes(syllable.id)}
                    selected={props.selectedChips.includes(syllable.id)}
                    disabledButVisible={props.answerDisabledState || isDisabled(syllable.id)}
                    onDragStart={() => {
                        localStorage.setItem('draggedChip', syllable.id);
                    }}
                    hotKey={isMobile ? undefined : (index + 1).toString()}
                />
            ))}
        </Box>

    );
}

export default SelectableChips;
