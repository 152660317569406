import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Report2EndpointGetHook(
    param_question: string, 
    param_answer: string, 
    param_reason: string, 
) {
    return GetWithHook(routes.Report, {
        question: param_question, 
        answer: param_answer, 
        reason: param_reason, 
    });
}

export function Report2EndpointGetPromise(
    param_question: string, 
    param_answer: string, 
    param_reason: string, 
) {
    return GetWithPromise(routes.Report, {
        question: param_question, 
        answer: param_answer, 
        reason: param_reason, 
    });
}

export function Report2EndpointPostPromise(
    param_question: string, 
    param_answer: string, 
    param_reason: string, 
) {
    return PostWithPromise(routes.ReportPost, {
        question: param_question, 
        answer: param_answer, 
        reason: param_reason, 
    });
}

