import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function AnswerInfo2EndpointGetHook(
    param_question: string, 
) {
    return GetWithHook(routes.AnswerInfo, {
        question: param_question, 
    });
}

export function AnswerInfo2EndpointGetPromise(
    param_question: string, 
) {
    return GetWithPromise(routes.AnswerInfo, {
        question: param_question, 
    });
}

