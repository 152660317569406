export const FLAGS = [
    'german',
    'spanish',
    'turkish',
    'russian',
    'chinese',
    'french',
    'arabic',
    'italian',
    'english',
    'portuguese',
    'dutch',
    'czech',
    'japanese',
    'swedish',
];
