import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Error';
import { ThemeProvider } from '@mui/material';
import { theme } from './generals/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import Website from './generals/Website';
import Welcome from './Welcome';
import Home from './Home';
import Course from './courses/Course';
import Vocabs from './vocabs/Vocabs';
import VocabsOverview from './vocabs/VocabsOverview';
import Admin from './Admin';
import Profile from './Profile';
import Lection from './courses/Lection/Lection';
import { TTS } from './generals/speak';
import Alphabet from './courses/Alphabet';
import Chatbots from './courses/Lection/Chatbots';
import Intro from './Intro';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Website>
                <Welcome />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/home',
        element: (
            <Website>
                <Home />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/home/:section',
        element: (
            <Website>
                <Home />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection',
        element: (
            <Website>
                <Course />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/intro/intro',
        element: (
            <Website noMenu={true}>
                <Intro />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection/lesson',
        element: (
            <Website noMenu={true}>
                <Lection
                    speak={TTS}
                />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/intro/intro/nomenu',
        element: (
            <Website noMenu={true}>
                <Intro
                    noMenu={true}
                />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection/lesson/nomenu',
        element: (
            <Website noMenu={true}>
                <Lection
                    speak={TTS}
                    noMenu={true}
                />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/vocab/:baseLanguage/:targetLanguage',
        element: (
            <Website>
                <VocabsOverview />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/vocab/:baseLanguage/:targetLanguage/train',
        element: (
            <Website>
                <Vocabs
                    speak={TTS}
                />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/alphabet',
        element: (
            <Website>
                <Alphabet />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/chatbots',
        element: (
            <Website>
                <Chatbots />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/overview',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table/:page',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table/:page/:perPage',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/profile',
        element: (
            <Website>
                <Profile />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '*',
        element: (
            <Website>
                <Welcome />
            </Website>
        ),
        errorElement: <Error />,
    }
]);

root.render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <div className="App">
                <RouterProvider router={router} />
            </div>
        </LocalizationProvider>
    </ThemeProvider>
);
