import { KeyboardReturn } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import useIsMobile from '../../constants/useIsMobile';
import Char from '../../generals/Char';
import Image from '../../generals/image';
import { getHost } from '../../modules/api/client';

interface Props {
    updateMode: () => void;
    currentNotice: string;
    noticeButtonText: string;
    lessonState: any;
    contentPosition: any;
}

function Notice(props: Props) {
    const isMobile = useIsMobile();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                props.updateMode();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Box
            sx={props.contentPosition}
        >
            <Typography
                variant='h5'
                sx={{
                    textAlign: 'center',
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                {props.currentNotice}
            </Typography>
            {props.lessonState.status === 200 && props.lessonState.data.image !== '' ?
                <Image
                    src={getHost() + '/image.php?file=' + props.lessonState.data.image}
                    styles={{
                        width: 200,
                        height: 'auto',
                        borderRadius: 20,
                    }}
                />
                : <Char
                    language="english"
                />
            }
            <Button
                variant='contained'
                onClick={() => { props.updateMode(); }}
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                {props.noticeButtonText}
                {!isMobile &&
                    <KeyboardReturn
                        sx={{
                            ml: 1,
                        }}
                    />
                }
            </Button>
        </Box>
    );
}

export default Notice;
