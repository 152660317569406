import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Verify2EndpointGetHook(
    param_code: string, 
) {
    return GetWithHook(routes.Verify, {
        code: param_code, 
    });
}

export function Verify2EndpointGetPromise(
    param_code: string, 
) {
    return GetWithPromise(routes.Verify, {
        code: param_code, 
    });
}

export function Verify2EndpointPostPromise(
    param_code: string, 
) {
    return PostWithPromise(routes.VerifyPost, {
        code: param_code, 
    });
}

