import { Box, Button } from '@mui/material';
import { theme } from '../generals/theme';
import { Logout2EndpointPostPromise } from '../routes/Logout';

interface Props {
    translations: any;
}

function Sidenav(props: Props) {
    const logout = () => {
        const promise = Logout2EndpointPostPromise();

        promise.then((response) => {
            if (response.status === 200) {
                window.location.href = '/';
            }
        });
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: 'calc(100vh - 64px)',
                borderRight: '1px solid #e0e0e0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '70%',
                    gap: 4,
                    boxSizing: 'border-box',
                    marginTop: 6,
                }}
            >
                <Button
                    href='/profile'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                >
                    {props.translations.profile}
                </Button>
                <Button
                    variant='contained'
                    href='/statistics'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.statistics}
                </Button>
                <Button
                    href='/shop'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.shop}
                </Button>
                <Button
                    href='/payment'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.payment}
                </Button>
                <Button
                    href='/support'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.support}
                </Button>
                <Button
                    href='/faq'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.faq}
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '10%',
                    gap: 3,
                }}
            >
                <Button
                    href='/options'
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                    disabled
                >
                    {props.translations.options}
                </Button>
                <Button
                    onClick={logout}
                    variant='contained'
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.dark,
                    }}
                >
                    {props.translations.logout}
                </Button>
            </Box>
        </Box >
    );
}

export default Sidenav;
