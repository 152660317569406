import { Send } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from '../../../generals/theme';
import useEndpoint from '../../../generals/useEndpoint';
import MissionChatResponse from '../../../responses/MissionChat';
import { MissionChat2EndpointGetHook, MissionChat2EndpointPostPromise } from '../../../routes/MissionChat';
import Message from './Message';

interface Props {
    chatbot: string;
    next: () => void;
}

function Chatbot(props: Props) {
    const [messagesState, setMessagesState] = useEndpoint<MissionChatResponse>(MissionChat2EndpointGetHook(props.chatbot, ''));

    const [messageState, setMessageState] = useState('');

    const sendMessage = (message: string) => {
        const promise = MissionChat2EndpointPostPromise(props.chatbot, message);

        const newMessages = messagesState.data.messages;
        newMessages.push({
            role: 'user',
            content: message,
        });

        setMessagesState({
            status: 200,
            userState: {
                userModel: {
                    name: messagesState.userState.userModel.name,
                }
            },
            data: {
                chatbot: messagesState.data.chatbot,
                isCompleted: messagesState.data.isCompleted,
                messages: newMessages,
            },
        });

        setSendingState(true);
        setMessageState('');

        promise.then((response) => {
            response.json().then((response: MissionChatResponse) => {
                setMessagesState(response);
                setSendingState(false);
            });
        });
    };

    const returnReverted = (messages: any[]) => {
        const reversedMessages = [...messages].reverse();

        return reversedMessages;
    };

    const [sendingState, setSendingState] = useState(false);

    useEffect(() => {
        if (messagesState.status === 200 && messagesState.data.isCompleted) {
            props.next();
        }
    }, [messagesState]);

    return (
        <Box
            sx={{
                width: { xs: '100%', md: '100%' },
                maxWidth: { xs: '100vw', md: '80vw' },
                height: { xs: '100%', md: '100%' },
                maxHeight: { xs: '100vh', md: '60vh' },
                display: 'flex',
                borderRadius: 4,
                backgroundColor: 'white',
                flexDirection: 'column',
                zIndex: 4,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            }}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingX: 2,
                    borderRadius: '16px 16px 0 0',
                    borderBottom: '3px solid rgba(255, 255, 255, 0.5)',
                }}
            >
                <Typography
                    variant="h4"
                    color="white"
                >
                    {messagesState.status === 200 && messagesState.data.chatbot.name}
                </Typography>
                <Box>
                </Box>
            </Box>
            {messagesState.status === 200 &&
                <Box
                    sx={{
                        height: 'calc(100% - 50px)',
                        width: '100%',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        overflowY: 'auto',
                        scrollBehavior: 'smooth',
                    }}
                >
                    {sendingState && (
                        <Message
                            message={{
                                role: 'assistant',
                                content: 'Thinking...',
                            }}
                            index={0}
                            chatbotTitle={messagesState.data.chatbot.name}
                            user={messagesState.userState?.userModel?.name}
                        />
                    )}
                    {messagesState.status === 200 &&
                        returnReverted(messagesState.data.messages).map((message, index) => (
                            <Message
                                key={index}
                                message={message}
                                index={index}
                                chatbotTitle={messagesState.data.chatbot.name}
                                user={messagesState.userState.userModel.name}
                            />
                        ))
                    }
                </Box>
            }
            <Box
                sx={{
                    height: 'auto',
                    display: 'flex',
                    position: 'relative',
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        bottom: 0,
                    }}
                >
                    <TextField
                        sx={{
                            width: '100%',
                            outline: '1px solid rgba(0, 0, 0, 0.5)',
                            borderRadius: 4,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: 4,
                                    border: 0,
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                            '.MuiInputBase-input': {
                                width: '90%'
                            },
                        }}
                        variant="outlined"
                        value={messageState}
                        onChange={(e) => setMessageState(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' || e.key === 'NumpadEnter' || e.key === 'Return') {
                                if (!e.shiftKey) {
                                    sendMessage(messageState);
                                    e.preventDefault();
                                }
                            }
                        }}
                        multiline
                        autoComplete="off"
                    />
                    <Button
                        onClick={() => sendMessage(messageState)}
                        variant="text"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <Send />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Chatbot;

