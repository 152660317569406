import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FLAGS } from './constants/flags';
import useIsMobile from './constants/useIsMobile';
import Char from './generals/Char';
import NewLogin from './generals/NewLogin';
import { theme } from './generals/theme';
import ucFirst from './generals/ucFirst';
import AnonResponse from './responses/Anon';
import EmptyResponse from './responses/EmptyResponse';
import EmptyResponseType from './responses/EmptyResponseType';
import { Anon2EndpointGetPromise, Anon2EndpointPostPromise } from './routes/Anon';

interface Props {
    currentLanguageState: string;
    translations: any;
}

function Join(props: Props) {
    const isMobile = useIsMobile();

    const [showLanguages, setShowLanguages] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);

    const [anon, setAnon] = useState<AnonResponse | EmptyResponseType>(EmptyResponse);

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    const joinCourse = (course: string) => {
        const promise = Anon2EndpointPostPromise(
            props.currentLanguageState,
            course,
        );

        promise.then((response) => {
            response.json().then((data: AnonResponse) => {
                window.location.href = data.data.firstLection;
            });
        });
    };

    const createAnon = () => {
        const promise = Anon2EndpointGetPromise(props.currentLanguageState, '');

        promise.then((response: any) => {
            setAnon(response);
            setShowLanguages(true);
        });
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            {!showLanguages &&
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <Char
                            language={props.currentLanguageState}
                            height={isMobile ? '125px' : '300px'}
                            width='auto'
                        />
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant='h4'
                            align='center'
                            gutterBottom
                        >
                            {props.translations.theEasyWayToLearnALanguage}
                        </Typography>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={createAnon}
                            sx={{
                                px: 8,
                                fontSize: '1.5rem',
                                marginTop: 2,
                            }}
                        >
                            {props.translations.getStarted}
                        </Button>
                        <Button
                            variant='text'
                            color='primary'
                            sx={{
                                marginTop: 2,
                            }}
                            onClick={() => setShowLogin(true)}
                        >
                            {props.translations.ialreadyhaveanaccount}
                        </Button>
                    </Box>
                </Box>
            }
            <NewLogin
                open={showLogin}
                translations={props.translations}
                close={() => setShowLogin(false)}
            />
            {showLanguages &&
                <Box>
                    <Typography
                        variant='h4'
                        align='center'
                        gutterBottom
                    >
                        {props.translations.iWantToLearn}
                    </Typography>
                    {anon.status === 200 &&
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                marginTop: 4,
                            }}
                        >
                            {anon.data.courses.map((course: string) => (
                                <Grid
                                    item
                                    key={course}
                                    xs={6}
                                    md={3}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: theme.palette.grey[200],
                                            borderRadius: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 2,
                                            gap: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => joinCourse(course)}
                                    >
                                        <Typography
                                            variant='h6'
                                            align='center'
                                        >
                                            {ucFirst(course)}
                                        </Typography>
                                        {flagExists(course) ?
                                            <img
                                                src={'/' + course + '.png'}
                                                alt={course}
                                                style={{
                                                    height: 28,
                                                    borderRadius: 5,
                                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                                }}
                                            />
                                            : <Box
                                                sx={{
                                                    width: 52,
                                                    height: 28,
                                                    borderRadius: '5px',
                                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                                    backgroundColor: theme.palette.grey[300],
                                                }}
                                            >
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Box>
            }
        </Box>
    );
}

export default Join;
