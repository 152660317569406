import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Experience2EndpointGetHook(
) {
    return GetWithHook(routes.Experience, {
    });
}

export function Experience2EndpointGetPromise(
) {
    return GetWithPromise(routes.Experience, {
    });
}

